.cover-image {
  height: 100%;
}

.react-icons {
  vertical-align: 'bottom' !important;
}

.card-shadow {
  -webkit-box-shadow: 25px 20px 28px -14px #000000!important;
  -moz-box-shadow: 25px 20px 28px -14px #000000 !important;
  box-shadow: 25px 20px 28px -14px #000000 !important;
  margin-top: 40px;
  margin-bottom: 0px;
}

.card-content {
  text-align: center;
}
.card-title {
  margin: 20px;
}

.card-content-info {
  margin-top: 20px;
  flex-grow: 5 !important;
}
.card-content-info p {
  margin: 5px !important;
  text-align: left;
}

.resume-container {
    text-align: left !important;
    padding: 10px;
}

.resume-header {
    margin-bottom: 0px;
}

.resume-title {
    margin: 0px;
    font-weight: 700;
    font-size: 24px;
}

.resume-company {
    margin: 0px;
}

.resume-org {
    margin: 0px;
    color: #3386de;
}

.resume-dates {
    margin: 0px;
    font-size: 16px;
    color: grey;
}

.resume-about {
  margin: 0px;
  font-size: 16px;
}

.spec-item {
  background-color: #3386de;
  padding: 2px;
  border-radius: 5px;
  margin: 5px 2px;
  color: white;
}
