.App {
  /* width: 100%; */
}

body {
  height: auto;
  width: auto;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  overflow-y: visible;
  /* height: auto; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.body-container {
  padding-top: 100px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.page-title-text {

}

.page-body-text {

}

h1 {
  font-family: 'Calibri', sans-serif;
  font-weight: 200;
  font-size: 40px;
}
h2 {
  font-family: 'Calibri', sans-serif;
  font-weight: 400;
}

p {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 20px;
}
